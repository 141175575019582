<ion-header>
  <app-header
    [title]="regionData?.pageTitle"
    [loading]="loading"
    (backAction)="goBack()"
    [backButtonLabel]="regionData?.labelBackButton"
    [logoUrl]="regionData?.pageLogo"
    (logoAction)="showRootPage()"
    [logoActionLabel]="regionData?.labelMainScreenButton"
  ></app-header>
</ion-header>

<ion-content
  color="dark"
  class="ion-padding"
>
  <!-- Loading-placeholder -->
  <ng-container *ngIf="isSupportedRegion() && loading">
    <div class="ion-margin-vertical text-style--size-2">
      <span
        class="loading-text"
        style="width: 33%"
      ></span>
    </div>
    <div class="ion-margin-vertical ion-padding-bottom">
      <span class="loading-text"></span>
      <span class="loading-text"></span>
      <span
        class="loading-text"
        style="width: 75%"
      ></span>
    </div>
  </ng-container>

  <router-outlet *ngIf="isSupportedRegion() && !loading"></router-outlet>

  <!-- Error/404 page -->
  <ng-container *ngIf="isSupportedRegion() && !loading && !dataAvailable">
    <h2>{{ errorHeader }}</h2>
    <p>
      {{ errorMessage }}
      <a
        [href]="errorContactUrl"
        target="_blank"
        rel="noopener noreferrer"
        style="color: red"
        >{{ errorContactUrl }}</a
      >
      <br /><br /><br />
      <a
        href="/"
        onclick="location.reload(true)"
        style="color: gold"
      >
        {{ errorRetry }}
      </a>
    </p>
  </ng-container>

  <!-- Main/Root/Regions-list page -->
  <ng-container *ngIf="!isSupportedRegion()">
    <div class="ion-margin-bottom ion-padding-bottom">
      <h2 class="text-style--header text-style--size-2">{{ pageHeader }}</h2>
      <div
        markdown
        [data]="pageIntroduction"
        [inline]="false"
      ></div>
    </div>
    <ul class="list-flat tile-grid tile-grid_base-2 ion-margin-vertical">
      <li
        *ngFor="let region of regions; index as index;"
        class="tile-grid--item"
      >
        <a
          *ngIf="region"
          [routerLink]="['/', region]"
          class="sheet--button action text-style--header text-style--size-08"
        >
          <span>{{ regionsLabels[index] }}</span>
        </a>
      </li>
      <li
        *ngIf="(regions.length % 2) === 1"
        aria-hidden="true"
        class="tile-grid--item"
      >
        <!-- filler-item for empty spot at 2nd-in-a-row -->
      </li>
    </ul>
    <br /><br /><br />
  </ng-container>

  <div
    class="floating-action-area is_bottom-end"
    *ngIf="isSupportedRegion() && hasContactOptions() && !getNotificationContent()"
  >
    <span
      class="contact-button"
      *ngIf="regionData.contactPhoneNumber"
    >
      <a
        [href]="'tel:' + regionData.contactPhoneNumber"
        target="_blank"
        rel="noopener noreferrer"
        (click)="logContactClick('tel')"
        class="contact-button--link"
      >
        <img
          src="assets/icons/contact_phone.png"
          alt="Phone"
          loading="lazy"
          decoding="async"
          width="48"
          height="48"
          class="contact-button--logo"
        />
      </a>
    </span>
    <span
      class="contact-button"
      *ngIf="regionData.contactWhatsAppLink"
    >
      <a
        [href]="regionData.contactWhatsAppLink"
        target="_blank"
        rel="noopener noreferrer"
        (click)="logContactClick('whatsapp')"
        class="contact-button--link"
      >
        <img
          src="assets/icons/contact_whatsapp.png"
          alt="WhatsApp"
          loading="lazy"
          decoding="async"
          width="48"
          height="48"
          class="contact-button--logo"
        />
      </a>
    </span>
    <span
      class="contact-button"
      *ngIf="regionData.contactTelegramLink"
    >
      <a
        [href]="regionData.contactTelegramLink"
        target="_blank"
        rel="noopener noreferrer"
        (click)="logContactClick('telegram')"
        class="contact-button--link"
      >
        <img
          src="assets/icons/contact_telegram.png"
          alt="WhatsApp"
          loading="lazy"
          decoding="async"
          width="48"
          height="48"
          class="contact-button--logo"
        />
      </a>
    </span>
  </div>
</ion-content>
<ion-footer
  *ngIf="!!getNotificationContent()"
  class="no-print"
>
  <div class="notification-bar ion-padding">
    <div
      markdown
      [data]="getNotificationContent()"
      [inline]="true"
    ></div>
  </div>
</ion-footer>
