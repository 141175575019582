// THIS FILE IS GENERATED BY 'npm run set-env-variables'

export const environment = {
  production: true,

  // Configuration/Feature-switches:
  envName: '',
  useServiceWorker: true,
  useOffers: false,
  useQandAs: true,
  useQandASearch: true,

  // Configuration of content
  appName: '',
  appLogoUrl: 'data:image/svg+xml;charset=UTF-8,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 243 150\'%3e%3cpath fill=\'%23f5333f\' d=\'M57 89H23V60h34V26h29v34h34v29H86v34H57V89zm108-15a41 41 0 0 1 56-39 49 49 0 1 0 0 77 41 41 0 0 1-56-38\'/%3e%3c/svg%3e',
  mainPageHeader: 'Helpful Information by The Red Cross',
  mainPageIntroduction: 'Select your language: \n \n Виберіть мову: \n \n Válaszd ki a nyelved: \n \n',
  mainPageNotification: '',
  errorHeader: 'Data Unavailable',
  errorMessage: 'Reach out to us at: ',
  errorContactUrl: 'https://github.com/financial-aid-ukraine/redcross.financial-aid-ukraine.info/issues/new',
  errorRetry: 'Try again?',

  // Regions:
  regions: 'en, uk, hu',
  regionsLabels: 'English, Українська, Magyar',
  regionsSheetIds: '1Jx4iqp7K7PSwQ9UmU96Q5R50Cu0ywdxxMD5uDSlU9ys, 1EnRmZyl4osJPNXd_m6hSDLevvB20s1Bid3vEGFr0p84, 1Mb9rZug0PFYEO7kcvtpVC5KoTqftf1JmL9JE7h4vT1I',

  // Third-party tokens:
  // Google Sheets API:
  google_sheets_api_key: 'AIzaSyAFjzsjU8uWPcD2lFYeCUV2PO9Yu_HRgJU',
  google_sheets_api_url: 'https://sheets.googleapis.com/v4/spreadsheets',

  // Application Insights
  appInsightsConnectionString: '',
};
